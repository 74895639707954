import React, { Fragment } from 'react';
import Hero9 from '../../components/hero9/hero9';
import Navbar from '../../components/Navbar/Navbar'




const HomePage9 = () => {

    return (
        <Fragment>
            <Navbar  hclass={'wpo-header-style-4'}/>
            <Hero9 />
        </Fragment>
    )
};

export default HomePage9;