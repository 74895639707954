
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';


const VideoModal = () => {
  
  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideo
        channel="custom"
        url={
          "https://drive.google.com/file/d/1RsRNHZCSJt_h_vHmcKY3L5vSIdyVYB3R/preview"
        }
        width={true}
        autoplay
        isOpen={isOpen}
        videoId="JqeWr83br5k"
        onClose={() => setOpen(false)}
      />

      <div className="video-btn">
        <ul>
          <li>
            <button className="wrap" onClick={() => setOpen(true)}>
              <i className="fi flaticon-play" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default VideoModal;