import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/img-11.png'
import coupleImg2 from '../../images/couple/img-2.png'
import coupleImg3 from '../../images/couple/shape.png'

const CoupleSection = (props) => {

    return (
        <section className={`wpo-couple-section section-padding ${props.cClass}`} id="couple">
            <div className="container">
                <div className="couple-area clearfix">
                    <div className="row align-items-center">
                        <div className="col col-md-5 col-12">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg1} alt="" />
                                </div>
                                <div className="couple-text">
                                    <h3>2019 Ev'leniyoruz Fuarı</h3>
                                    <p>TÜYAP işbirliği ile 21-24 Mart tarihleri arasında gerçekleşen Doğu Anadolu Ev'leniyoruz fuarında bizler de yerimizi aldık. Ekibimiz ile birlikte verdiğimiz yoğun çalışmalar neticesinde fuarın en gözde firmalarından biri olduk ve misafirlerimize oldukça eğlenceli dakikalar yaşattık.</p>
                                    <div className="social">
                                        <ul>
                                            {/* <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li> */}
                                            <li><a href="https://www.instagram.com/feyzaorganizasyn"><i className="ti-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-2 col-12">
                            <div className="middle-couple-shape">
                                <div className="shape" >
                                    <img id="love-shape" src={coupleImg3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-5 col-12">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg2} alt="" />
                                </div>
                                <div className="couple-text">
                                    <h3>2020 Ev'leniyoruz Fuarı</h3>
                                    <p>TÜYAP işbirliği ile 11-15 Mart tarihleri arasında gerçekleşen Doğu Anadolu Ev'leniyoruz fuarında bizler de yerimizi aldık. Ekibimiz ile birlikte verdiğimiz yoğun çalışmalar neticesinde fuarın en gözde firmalarından biri olduk ve misafirlerimize oldukça eğlenceli dakikalar yaşattık. </p>
                                    <div className="social">
                                        <ul>
                                            {/* <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li> */}
                                            <li><a href="https://www.instagram.com/feyzaorganizasyn"><i className="ti-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection;