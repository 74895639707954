import React from "react";

const Hero7 = () => {
    return (

        <section className="static-hero-s3">
            <div className="container-fluid">
                <div className="wpo-hero-date">
                    <h2>Muhammad & Amatullah</h2>
                    <p>We Are Getting Married November 15,2022</p>
                </div>
            </div>
        </section>
    )
}

export default Hero7;