import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    return(
        <section className="mt-3 wpo-contact-pg-section section-padding" id="contact" >
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <a href='https://www.google.com/maps/dir//erzurum+feyza+organizasyon/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x406e5fc8729dfac3:0x233463ba620ca96c?sa=X&ved=2ahUKEwiI0djk5-_7AhXuQ_EDHceWCR4Q9Rd6BAhcEAU' >
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Adresimiz</h2>
                                            <p>Lalapaşa Mah. Şelale Evler Cad. N:9/B YAKUTİYE / ERZURUM</p>
                                        </div>
                                    </div>
                                    </a>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <a href='mailto:erzurumfeyzaorganizasyon@gmail.com'>
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Mail Adresimiz</h2>
                                            <p>erzurumfeyzaorganizasyon@gmail.com</p>
                                            <p>info@feyzaorganizasyon.com</p>
                                            {/* <p>helloyou@gmail.com</p> */}
                                        </div>
                                    </div>
                                    </a>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <a href='tel:+905449012351'>
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Şimdi arayın</h2>
                                            <p>0544 901 23 51</p><br/>
                                        </div>
                                    </div>
                                    </a>

                                </div> 
                            </div>
                        </div>
                        {/* <div className="wpo-contact-title">
                            <h2>Have Any Question?</h2>
                            <p>It is a long established fact that a reader will be distracted
                                content of a page when looking.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div> */}
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d637.0441306175647!2d41.264114419221!3d39.910411454651594!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x233463ba620ca96c!2zRXJ6dXJ1bSBGZXl6YSBPcmdhbml6YXN5b24gfCBEw7zEn8O8biAtIEvEsW5hIC0gTmnFn2FuIC0gRG_En3VtIEfDvG7DvCAtIFPDvG5uZXQgLSBFdmxpbGlrIFRla2xpZmk!5e0!3m2!1str!2str!4v1670701540689!5m2!1str!2zRXJ6dXJ1bSBGZXl6YSBPcmdhbml6YXN5b24gfCBEw7zEn8O8biAtIEvEsW5hIC0gTmnFn2FuIC0gRG_En3VtIEfDvG7DvCAtIFPDvG5uZXQgLSBFdmxpbGlrIFRla2xpZmk"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
