import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Contactpage from '../../components/Contactpage/Contactpage';


const ContactPage =() => {
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'Bizimle iletişime geçin'} pagesub={'İletişim'}/> 
            <Contactpage/> 
            {/* <Footer/> */}
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

