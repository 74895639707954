import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'
import Whatsapp from '../../images/whatsapp.svg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    return (
        <div className="wpo-site-footer text-center" style={{marginTop:30}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-image">
                            <Link className="logo" to="/"><img style={{width:'100%'}} src={Logo} alt=""/></Link>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="footer-link">
                            {/* <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/home2">Wedding</Link></li>
                                <li><Link to="/portfolio-grid">Portfolio</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/shop">Shop</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="link-widget">
                            <ul>
                                <li><a href="https://www.instagram.com/feyzaorganizasyn"><i className="ti-instagram"></i></a></li>
                                <li><a href="tel:+905449012351"><i><img src={Whatsapp} style={{width:'50%'}} /></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="copyright">
                            <p>© Copyright 2022 | <a href='https://www.apithon.com.tr'>Apithon Software</a> | Tüm hakları saklıdır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;