// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";
import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";
import blogImg9 from "../images/blog/img-9.jpg";
import blogImg10 from "../images/blog/img-10.jpg";
import blogImg11 from "../images/blog/img-11.jpg";
import blogImg12 from "../images/blog/img-12.jpg";

import blogSingleImg1 from "../images/blog-details/img-1.jpg";
import blogSingleImg2 from "../images/blog-details/img-2.jpg";
import blogSingleImg3 from "../images/blog-details/img-3.jpg";
import blogSingleImg4 from "../images/blog-details/img-4.jpg";
import blogSingleImg5 from "../images/blog-details/img-5.jpg";
import blogSingleImg6 from "../images/blog-details/img-6.jpg";
import blogSingleImg7 from "../images/blog-details/img-7.jpg";
import blogSingleImg8 from "../images/blog-details/img-8.jpg";
import blogSingleImg9 from "../images/blog-details/img-9.jpg";
import blogSingleImg10 from "../images/blog-details/img-10.jpg";
import blogSingleImg11 from "../images/blog-details/img-11.jpg";
import blogSingleImg12 from "../images/blog-details/img-12.jpg";



const blogs = [
    {
        id: '1',
        title: 'Photography is the important part of wedding.',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2022',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Best Wedding Gown For Your Dream Day',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'David Luis',
        create_at: '23 Sep 2022',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'Top 10 wedding fresh flower decoration idea.',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '4',
        title: 'Best wedding gift you may like & choose.',
        screens: blogImg4,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg4,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '5',
        title: 'Photography is the important part of wedding.',
        screens: blogImg5,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2022',
        blogSingleImg:blogSingleImg5, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '6',
        title: 'Best Wedding Gown For Your Dream Day',
        screens: blogImg6,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'David Luis',
        create_at: '23 Sep 2022',
        blogSingleImg:blogSingleImg6, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '7',
        title: 'Top 10 wedding fresh flower decoration idea.',
        screens: blogImg7,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg7,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '8',
        title: 'Best wedding gift you may like & choose.',
        screens: blogImg8,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg8,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '9',
        title: 'Photography is the important part of wedding.',
        screens: blogImg9,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2022',
        blogSingleImg:blogSingleImg9, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '10',
        title: 'Best Wedding Gown For Your Dream Day',
        screens: blogImg10,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'David Luis',
        create_at: '23 Sep 2022',
        blogSingleImg:blogSingleImg10, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '11',
        title: 'Top 10 wedding fresh flower decoration idea.',
        screens: blogImg11,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg11,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '12',
        title: 'Best wedding gift you may like & choose.',
        screens: blogImg12,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        create_at: '21 Sep 2022',
        blogSingleImg:blogSingleImg12,
        comment:'95',
        blClass:'format-video',
    },
];
export default blogs;